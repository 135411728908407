import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useHistory,
} from "react-router-dom";
import Login from "./login";
import Create from "./admin/complaints/create";
import Admin from "./admin/index";
import { getMessages,getImagesSliders,getSettingByKey,getComplaintTypes,getCompanies } from "../redux/core";
import {TAMANIO_MAXIMO_IMAGEN} from '../config/index'
const Main = () => {
  const isLogin = useSelector((state) => state.auth.isLogin);
  const is_superuser = useSelector((state) => state.auth.token);
  const [auth, setAuth] = useState(false);
  const [is_superuser_auth, setIs_superuser_auth] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(window.location.pathname);
    dispatch(getMessages());
    dispatch(getImagesSliders('basic-slider'));
    dispatch(getSettingByKey(TAMANIO_MAXIMO_IMAGEN));
    if (isLogin) {
      setAuth(isLogin);
      if (is_superuser) {
        dispatch(getComplaintTypes(is_superuser.token))
        dispatch(getCompanies(is_superuser.token))
        setIs_superuser_auth(is_superuser.is_superuser);
      }
    }

    if (!isLogin) {
      document.body.classList.add("vertical-layout");
      document.body.classList.add("vertical-menu-modern");
      document.body.classList.add("1-column");
      document.body.classList.add("navbar-sticky");
      document.body.classList.add("footer-static");
      document.body.classList.add("bg-full-screen-image");
      document.body.classList.add("blank-page");
    } else {
      document.body.classList.remove("vertical-layout");
      document.body.classList.remove("vertical-menu-modern");
      document.body.classList.remove("1-column");
      document.body.classList.remove("navbar-sticky");
      document.body.classList.remove("footer-static");
      document.body.classList.remove("bg-full-screen-image");
      document.body.classList.remove("blank-page");
    }
  }, [isLogin, is_superuser]);

  let Render = () => {
    if (is_superuser_auth) {
      return (
        <>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/" exact>
            <Create />
          </Route>
        </>
      );
    } else {
      return (
        <Route path="/" exact>
          <Create />
        </Route>
      );
    }
  };

  return (
    <Router>
      <Switch>
        {auth ? (
          <>
            <Render/>
         
          </>
        ) : (
          <>

          <Route path="/" exact>
            <Login />
          </Route>
        
        </>

        )}
      </Switch>
    </Router>
  );
};

export default Main;
