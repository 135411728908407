import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage, deleteImage } from "../../redux/core";
import { BASE_API } from "../../config/index";
import "./index.css";
const BasicSlider = ({ data }) => {
  if (data.length == 0) return null;
  return (
    <div
      id="carousel-example-generic"
      class="carousel slide"
      data-ride="carousel"
    >
      <ol class="carousel-indicators">
        {data.map((item, index) => (
          <li
            data-target="#carousel-example-generic"
            data-slide-to={index == 0 ? "active" : ""}
            class="active"
          ></li>
        ))}
      </ol>
      <div class="carousel-inner" role="listbox">
        {data.map((item, index) => (
          <div class={`carousel-item ${index == 0 ? "active" : ""}`}>
            <img
              class="img-fluid"
              src={BASE_API.substring(0, BASE_API.length - 1) + item.image}
              alt={item.alt}
              style={{ width: "100%", height: "250px" }}
            />
          </div>
        ))}
      </div>
      <a
        class="carousel-control-prev"
        href="#carousel-example-generic"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carousel-example-generic"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  );
};

const CreateBasicSlider = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const get_image = useSelector((state) => state.comp.get_image);
  const create_image = useSelector((state) => state.comp.create_image);
  const get_setting = useSelector((state) => state.comp.get_setting);
  const [count, setCount] = useState(1);
  const [lista, setLista] = useState([
    {
      id: 0,
      image: "",
      alt: "",
      position: 1,
      file: null,
    },
  ]);
  const [load, setLoad] = useState(false);
  const [msj, setMsj] = useState({
    type: "",
    message: "",
  });
  const [setting, setSetting] = useState({
    status: 0,
    message: "",
    data: {},
  });

  useEffect(() => {
    if (get_setting.status == 200) {
      setSetting(get_setting);
    }
    if (get_image.status == 200) {
      if (get_image.data.length > 0) {
        //a cada item de la lista al campo imagen le concateno BASE_API
        setLista(
          get_image.data.map((item) => {
            return {
              id: item.id,
              image: BASE_API.substring(0, BASE_API.length - 1) + item.image,
              alt: item.alt,
              position: item.position,
              file: null,
            };
          })
        );
      }
    }
    console.log(lista);
  }, [get_image, get_setting]);

  const addItem = () => {
    setCount(count + 1);
    const item = {
      id: 0,
      image: "",
      alt: "",
      position: 0,
      file: null,
    };
    setLista([...lista, item]);
  };
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const send_data = async () => {
    setLoad(true);
    if (lista.length > 0) {
      for (let i = 0; i < lista.length; i++) {
        let form = new FormData();
        form.append("id", lista[i].id);
        form.append("image", lista[i].file);
        form.append("position", lista[i].position);
        form.append("alt", lista[i].alt);
        dispatch(uploadImage(form, token.token));
      }
      await sleep(2000);
      window.location.reload();
    } else {
      setLoad(false);
    }
  };

  const removeItem = (index) => {
    setCount(count - 1);
    if (lista[index].id > 0) {
      dispatch(deleteImage({ id: lista[index].id }, token.token));
    }
    const newLista = lista.filter((item, i) => i !== index);
    setLista(newLista);
  };

  const removeSrcItem = (index) => {
    const newLista = lista.map((item, i) => {
      if (i === index) {
        item.image = "";
        item.file = null;
      }
      return item;
    });
    setLista(newLista);
  };

  const handleSrcItem = (index, e) => {
    let TAMANIO_MAXIMO_IMAGEN = 5000000;
    if(setting.data){
      TAMANIO_MAXIMO_IMAGEN = setting.data.value;
      TAMANIO_MAXIMO_IMAGEN = parseFloat(TAMANIO_MAXIMO_IMAGEN);
    }
    let value = URL.createObjectURL(e.target.files[0]);
    let file = e.target.files[0];
    var siezekiloByte = parseInt(file.size / 1024);
    
    if (siezekiloByte > TAMANIO_MAXIMO_IMAGEN) {
      e.target.value = "";
      setMsj({
        type: "danger",
        message: "El tamaño de la imagen no debe superar los " + TAMANIO_MAXIMO_IMAGEN + " Kb",
      });
    } else {
      const newLista = lista.map((item, i) => {
        if (i === index) {
          item.image = value;
          item.file = file;
        }
        return item;
      });
      setLista(newLista);
    }
  };

  const handleValue = (index, key, value) => {
    const newLista = lista.map((item, i) => {
      if (i === index) {
        item[key] = value;
      }
      return item;
    });
    setLista(newLista);
  };
  return (
    <div class="row card p-3">
      {
        msj.type !=""?
        <div class={`alert alert-dismissible alert-${msj.type}`}>
          <button type="button" class="close" data-dismiss="alert" onClick={
            ()=>{
              setMsj({
                type: "",
                message: "",
              });
            }
          }>
            &times;
          </button>
          <strong>{msj.message}</strong>
        </div>
        :
        ""
      }
      <div class="row">
        <button
          type="button"
          class="btn btn-primary round mr-1 mb-1"
          onClick={() => addItem()}
        >
          +
        </button>
        {load ? (
          <button class="btn btn-success mb-1" type="button" disabled="">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        ) : (
          <button
            type="button"
            class="btn btn-success round mr-1 mb-1"
            onClick={() => send_data()}
          >
            Guardar
          </button>
        )}

        <h6 class="mt-1">{count}</h6>
      </div>

      <div class="accordion" id="accordionExample">
        {lista.map((item, index) => (
          <div class="card">
            <div class="card-header row" id="headingOne">
              <h5 class="mb-0">Imagen {index + 1}</h5>
              <button
                class="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${index}`}
                aria-expanded="true"
                aria-controls={`collapse${index}`}
              >
                <i class="bx bx-chevron-down"></i>
              </button>
              <a onClick={() => removeItem(index)}>
                <i class="badge-circle badge-circle-light-danger bx bx-trash font-medium-1"></i>
              </a>
            </div>
            <div
              id={`collapse${index}`}
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="form-group">
                  <label for={`alt${index}`}>Alt image</label>
                  <input
                    type="text"
                    class="form-control"
                    id={`alt${index}`}
                    aria-describedby="Alt"
                    placeholder="Image Text"
                    onChange={(e) => handleValue(index, "alt", e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label for={`pos-${index}`}>Posición</label>
                  <input
                    type="number"
                    class="form-control"
                    id={`pos-${index}`}
                    aria-describedby="Position"
                    onChange={(e) =>
                      handleValue(index, "position", e.target.value)
                    }
                  />
                </div>
                <div class="form-group">
                  {item.image ? (
                    <div class="button-container">
                      <img class="img-fluid" src={item.image} alt={item.alt} />
                      <a
                        href="#"
                        class="btn btn-danger"
                        onClick={() => removeSrcItem(index)}
                      >
                        {" "}
                        <i class="bx bx-trash font-medium-1"></i>
                      </a>
                    </div>
                  ) : (
                    <>
                      <label for={`alt${index}`}>Imagen</label>

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Upload</span>
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={(e) => handleSrcItem(index, e)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
const basicSlider = {
  title: "basic-slider",
  data: {
    index: BasicSlider,
    create: CreateBasicSlider,
  },
};

export default basicSlider;
