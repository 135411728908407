import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateMessages,
  UPDATE_MESSAGE,
  clear_state,
} from "../../../redux/core";

const Messages = () => {
  const dispatch = useDispatch();
  const get_messages = useSelector((state) => state.comp.get_messages);
  const update_message = useSelector((state) => state.comp.update_message);
  const _token = useSelector((state) => state.auth.token);
  const [token, setToken] = useState("");

  const [messages, setMessages] = useState({
    data: [],
    messages: "",
    status: 0,
  });

  const [msj, setMsj] = useState({
    msj: "",
    type: "",
  });
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(() => {
    if (_token) {
      setToken(_token.token);
    }
    if (get_messages) {
      setMessages(get_messages);
      if(get_messages.data){
        setData(get_messages.data);

      }
    }
    if (update_message) {
     if(msj.msj){
      clear_message()
     }
      if (update_message.status == 200) {
        setLoad(false)
        setMsj({
          msj: update_message.message,
          type: "success",
        });
        setShow(true);
        sleep(3000).then(() => {
          clear_message();
        });
        if (get_messages) {
          setData(get_messages.data);
          setMessages(get_messages);
        }
      } else if (update_message.status != 0) {
        setLoad(false)

        setMsj({
          msj: update_message.message,
          type: "danger",
        });
        setShow(true);
        sleep(3000).then(() => {
          clear_message();
        });
      }
    }
  }, [get_messages, update_message, data]);

  const handlerChange = (e, index) => {
    const obj = {
      id: e.target.id,
      text: e.target.value,
      key: e.target.name,
      state: true,
    };

    setData([
      data.map((item, i) => {
        if (i == index) {
          item.text = e.target.value;
        }
        return item;
      }),
    ]);
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    sleep(3000).then(() => {
      dispatch(updateMessages(data, token));
    });

  };

  const clear_message = () => {
    setShow(false);
    setMsj({
      msj: "",
      type: "",
    });
    dispatch(
      clear_state(UPDATE_MESSAGE, {
        status: 0,
        message: "",
      })
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h4>Mensajes</h4>
            </div>
            <div className="card-body">
              <form method="POST" onSubmit={(e) => handlerSubmit(e)}>
                {msj.msj != "" && show ? (
                  <div
                    class={`alert alert-${msj.type} alert-dismissible fade ${
                      show ? "show" : "hide"
                    }`}
                    role="alert"
                  >
                    {msj.msj}
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={() => {
                        clear_message();
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ) : null}
                {messages.data.map((i, x) => (
                  <div className="form-group row" key={x}>
                    <label className="col-sm-2 col-form-label">{i.key}</label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Message"
                        onChange={(e) => handlerChange(e, x)}
                        name={i.key}
                        id={i.id}
                        value={i.text}
                      />
                    </div>
                  </div>
                ))}

                {load ? (
                   <button class="btn btn-primary mb-1" type="button" disabled="">
                   <span
                     class="spinner-border spinner-border-sm"
                     role="status"
                     aria-hidden="true"
                   ></span>
                 </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
