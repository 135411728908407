import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paginate from "../../../components/admin/paginate";
import Modal from "../../../components/admin/modal";
import { Link } from "react-router-dom";
import { delete_user, DELETE_USER, clear_state } from "../../../redux/auth";
const Users = () => {
  const get_users = useSelector((state) => state.auth.get_users);
  const _token = useSelector((state) => state.auth.token);
  const _delete_user = useSelector((state) => state.auth.delete_user);
  const dispatch = useDispatch();
  const [users, setUsers] = useState({
    messages: "",
    status: 0,
    data: [],
  });
  const [token,setToken] = useState('');
  const [usersPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [userSelect, setUserSelect] = useState({
    id: "",
    username: "",
    email: "",
  });

  const [msj, setMsj] = useState({
    type: "",
    text: "",
  });
  const [show, setShow] = useState(false);
  useEffect(() => {
    if(_token){
      setToken(_token.token);
    }
    if (get_users) {
      setUsers(get_users);
    }
    if (_delete_user) {
      if (_delete_user.status === 200) {
        setMsj({
          type: "success",
          text: "Usuario eliminado",
        });
        setShow(true);
      } else if (_delete_user.status != 0) {
        setMsj({
          type: "danger",
          text: "Error al eliminar usuario",
        });
        setShow(true);
      }
    }
  }, [get_users]);
  const [dataFromPaginate, setDataFromPaginate] = useState(null);

  const updateDataFromPaginate = (data) => setDataFromPaginate(data);

  const renderUserList = () =>
    dataFromPaginate
      ? dataFromPaginate.map((item, index) => (
          <tr key={index}>
            <td>{item.username}</td>
            <td>{item.email}</td>
            <td>{item.is_active ? "Activo" : "Inactivo"}</td>
            <td>{item.date_joined}</td>

            <td>
              <Link
                to={"/admin/editar-usuario/" + item.id}
                style={{ cursor: "pointer" }}
              >
                <i class="badge-circle badge-circle-light-warning bx bx-show font-medium-1"></i>
              </Link>
              <a style={{ cursor: "pointer" }}>
                <i class="badge-circle badge-circle-light-danger bx bx-trash font-medium-1"></i>
              </a>
            </td>
          </tr>
        ))
      : users.data.map((item, index) => {
          if (index < usersPerPage) {
            return (
              <tr key={index}>
                <td>{item.username}</td>
                <td>{item.email}</td>
                <td>{item.is_active ? "Activo" : "Inactivo"}</td>
                <td>{item.date_joined}</td>

                <td>
                  <Link
                    to={"/admin/editar-usuario/" + item.id}
                    style={{ cursor: "pointer" }}
                  >
                    <i class="badge-circle badge-circle-light-warning bx bx-show font-medium-1"></i>
                  </Link>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setUserSelect(item);
                      setShowModal(true);
                    }}
                  >
                    <i class="badge-circle badge-circle-light-danger bx bx-trash font-medium-1"></i>
                  </a>
                </td>
              </tr>
            );
          } else {
            return null;
          }
        });
  return (
    <section id="table-transactions">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title"></h5>
          <Link to="/admin/crear-usuario" className="btn btn-primary  ml-1">
            <i class="bx bx-plus"></i>
          </Link>
          <div class="heading-elements">
            <ul class="list-inline mb-0 d-flex align-items-center">
              <li>
                <fieldset class="has-icon-left">
                  <input type="text" class="form-control single-daterange" />
                  <div class="form-control-position">
                    <i class="bx bx-search font-medium-1"></i>
                  </div>
                </fieldset>
              </li>
            </ul>
          </div>
        </div>
        {msj.text != "" ? (
          <div
            class={`alert alert-${msj.type} alert-dismissible fade ${
              show ? "show" : "hide"
            } ml-3 mr-3`}
            role="alert"
          >
            {msj.text}
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => {
                setShow(false);
                setMsj({
                  text: "",
                  type: "",
                });
                dispatch(
                  clear_state(DELETE_USER, {
                    status: 0,
                    message: "",
                  })
                );
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : null}
        {users.data ? (
          <Paginate
            data={users.data}
            setData={updateDataFromPaginate}
            itemsPerPage={usersPerPage}
          />
        ) : null}

        <div class="table-responsive">
          <table id="table-extended-transactions" class="table mb-0">
            <thead>
              <tr>
                <th>Usuario</th>
                <th>Correo</th>
                <th>Estado</th>
                <th>Fecha Creacion</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>{users.data ? renderUserList() : null}</tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} handleClose={() => setShowModal(!showModal)} className="modal-main" style={{}}>
        <div class="modal-header">
          <h5 class="modal-title">Eliminar Usuario</h5>
        </div>
        <div class="modal-body">
          <p>¿Esta seguro de eliminar el usuario {userSelect.username}?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger"
            onClick={() => {
              dispatch(delete_user(userSelect.id,token));
              setShowModal(false);
            }}
          >
            Eliminar
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </button>
        </div>
      </Modal>
    </section>
  );
};
export default Users;
