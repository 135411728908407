import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { update_user, UPDATE_USER, clear_state } from "../../../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

const UpdateUser = () => {
  let history = useHistory();
  let { id } = useParams();
  const get_users = useSelector((state) => state.auth.get_users);
  const _update_user = useSelector((state) => state.auth.update_user);
  const _token = useSelector((state) => state.auth.token);
  const [token, setToken] = useState("");

  const [msj, setMsj] = useState({
    type: "",
    text: "",
  });
  const dispatch = useDispatch();
  const [data, setData] = useState({
    username: "",
    email: "",
    password: "",
  });
  useEffect(() => {
    if (_token) {
      setToken(_token.token);
    }
    if (_update_user) {
      if (_update_user.status == 200) {
        setMsj({
          type: "success",
          text: "Usuario actualizado correctamente",
        });
        dispatch(
          clear_state(UPDATE_USER, {
            status: 0,
            message: "",
          })
        );
        history.push("/admin/usuarios");
      }else{
        setMsj({
          type: "danger",
          text: _update_user.message,
        });
      }
    }
    if (get_users) {
      if (get_users.data) {
        const datafind = get_users.data.find((item) => item.id == id);
        if (datafind) {
          setData(datafind);
        }
      }
    }
  }, [_update_user]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const { username, email, password } = data;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "" || email === "" || password === "") {
      setMsj({
        text: "Todos los campos son obligatorios",
        type: "danger",
      });
    } else {
      dispatch(update_user(data, id, token));
    }
  };
  return (
    <form method="post" onSubmit={(e) => handleSubmit(e)}>
      {msj.text !== "" ? (
        <div className={`alert alert-${msj.type}`}>{msj.text}</div>
      ) : null}
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Usuario</label>
        <input
          type="text"
          className="form-control"
          onChange={(e) => handleChange(e)}
          name="username"
          aria-describedby="emailHelp"
          placeholder="Usuario"
          value={username}
        />
      </div>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Correo</label>
        <input
          type="email"
          className="form-control"
          onChange={(e) => handleChange(e)}
          name="email"
          aria-describedby="emailHelp"
          value={email}
          placeholder="Correo"
        />
      </div>
      <div className="form-group">
        <label htmlFor="exampleInputPassword1">Contraseña</label>
        <input
          type="password"
          className="form-control"
          onChange={(e) => handleChange(e)}
          name="password"
          placeholder="Contraseña"
        />
      </div>

      <button type="submit" className="btn btn-primary">
        Guardar
      </button>
      <Link to="/admin/usuarios" className="btn btn-danger">
        Cancelar
      </Link>
    </form>
  );
};

export default UpdateUser;
