import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/auth";
import { BrowserRouter as Router, Route, Link, Switch,useHistory } from "react-router-dom";

const Navbar = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const username = useSelector((state) => state.auth.user);
  const _token = useSelector((state) => state.auth.token);
  const [token, setToken] = useState("");
  useEffect(() => {
    if (_token) {
      if (_token.token) {
        setToken(_token.token);
      }else{
        history.push("/")
        window.location.reload();
      }
    }else{
      history.push("/")
      window.location.reload();

    }
  }, [_token]);
  return (
    <nav class="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top " >
      <div class="navbar-wrapper">
        <div class="navbar-container content ">
          <div class="navbar-collapse" id="navbar-mobile">
            <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
              <ul class="nav navbar-nav">
                <li class="nav-item mobile-menu d-xl-none mr-auto">
                  <a
                    class="nav-link nav-menu-main menu-toggle hidden-xs"
                    href="#"
                  >
                    <i class="ficon bx bx-menu"></i>
                  </a>
                </li>
              </ul>
            </div>
            <ul class="nav navbar-nav float-right">
              <li class="dropdown dropdown-language nav-item">
                <a
                  class="dropdown-toggle nav-link"
                  id="dropdown-flag"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></a>
              </li>
              <li class="nav-item d-none d-lg-block">
                <a class="nav-link nav-link-expand">
                  <i class="ficon bx bx-fullscreen"></i>
                </a>
              </li>

              <li class="dropdown dropdown-user nav-item">
                <a
                  class="dropdown-toggle nav-link dropdown-user-link"
                  href="#"
                  data-toggle="dropdown"
                >
                  <div class="user-nav d-sm-flex d-none">
                    <span class="user-name">{username}</span>
                  </div>
                  <span>
                    <h2>
                      <i class="bx bx-user-circle"></i>
                    </h2>
                  </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right pb-0">
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      dispatch(logout(token));
                    }}
                  >
                    <i class="bx bx-power-off mr-50"></i>Salir
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
