import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  updateCompany,
  clear_state,
  getCompanies,
  UPDATE_COMPANY,
} from "../../../redux/core";
import { useSelector, useDispatch } from "react-redux";

const UpdateCompany = () => {
  let { id } = useParams();
  let history = useHistory();

  const [list, setList] = useState({
    status: 0,

    data: [],
    messages: "",
  });
  const dispatch = useDispatch();
  const companies_list = useSelector((state) => state.comp.get_companies);
  const update_company = useSelector((state) => state.comp.update_company);
  const _token = useSelector((state) => state.auth.token);
  const [token, setToken] = useState("");
  const [data, setData] = useState({
    name: "",
  });
  const [msj, setMsj] = useState({
    type: "",
    text: "",
  });

  useEffect(() => {
    if (_token) {
      setToken(_token.token);
    }

    if (update_company) {
      if (update_company.status == 200) {
        setMsj({
          type: "success",
          text: "Empresa actualizada correctamente",
        });
        dispatch(
          clear_state(UPDATE_COMPANY, {
            status: 0,
            message: "",
          })
        );
        dispatch(getCompanies(token));

        history.push("/admin/empresas/");
      } else {
        setMsj({
          type: "danger",
          text: update_company.message,
        });
      }
    }
    if (companies_list) {
      if (companies_list.data) {
        const datafind = companies_list.data.find(
          (item) => item.id_company == id
        );
        if (datafind) {
          setData(datafind);
        }
      }
    }
  }, [update_company]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const { name } = data;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setMsj({
        text: "Todos los campos son obligatorios",
        type: "danger",
      });
    } else {
      dispatch(updateCompany(id, data, token));
    }
  };

  return (
    <form method="post" onSubmit={(e) => handleSubmit(e)}>
      {msj.text !== "" ? (
        <div className={`alert alert-${msj.type}`}>{msj.text}</div>
      ) : null}
      <div className="form-group">
        <label htmlFor="nombre">Nombre</label>
        <input
          id="nombre"
          type="text"
          className="form-control"
          onChange={(e) => handleChange(e)}
          name="name"
          aria-describedby="emailHelp"
          placeholder="Nombre"
          value={name}
        />
      </div>

      <button type="submit" className="btn btn-primary">
        Guardar
      </button>
      <Link to="/admin/empresas/" className="btn btn-danger">
        Cancelar
      </Link>
    </form>
  );
};

export default UpdateCompany;
