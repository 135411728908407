export const BASE_API = 'https://bak.denuncias.orocash.ec/';
export const LOGIN_PATH = 'auth/login'
export const COMPLAINTS = 'complaints/create'
export const COMPLAINTS_LIST = 'complaints/list'

export const MESSAGES_LIST = 'messages/list'
export const MESSAGES_UPDATE = 'messages/update'

export const USER_LIST = 'auth/list'
export const USER_UPDATE = 'auth/update/'
export const USER_CREATE = 'auth/create'
export const USER_DELETE = 'auth/delete/'

export const USER_LOGOUT = 'auth/logout'

export const IMAGE_CREATE = 'slider/create';
export const IMAGE_LIST = 'slider/list';
export const IMAGE_DELETE = 'slider/delete';

export const SETTING_GET = 'settings/'

export const COMPLAINT_TYPES_LIST = 'complaint_types/list';
export const COMPLAINT_TYPES_CREATE = 'complaint_types/create';
export const COMPLAINT_TYPES_UPDATE = 'complaint_types/update';

export const COMPANY_CREATE = 'company/create';
export const COMPANY_LIST = 'company/list';
export const COMPANY_UPDATE = 'company/update';

export const REPORTER_CREATE = 'task/create';
export const TASK_STATUS = 'task/status/';

export const MEDIA_COMPLAINT_CREATE = "files/complaint/create";
export const MEDIA_COMPLAINT_LIST = "files/complaint/list";

export const COLOR = '#F1C71A';
export const TEXT_COLOR = '#fff';
export const APP_NAME = 'Sistema de Denuncias';

//TAMAÑO EN KB
export const TAMANIO_MAXIMO_IMAGEN ='TAMANIO_MAXIMO_IMAGEN';