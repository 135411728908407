import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paginate from "../../../components/admin/paginate";
import { Link } from "react-router-dom";



const ComplaintTypes = () => {
    const [list, setList] = useState({
        status: 0,
    
        data: [],
        messages: "",
      });
      const complaint_types = useSelector((state) => state.comp.get_complaint_types);
      const [itemPerPage] = useState(10);
    
      useEffect(() => {
        if (complaint_types) {
          setList(complaint_types);
        }
      }, [complaint_types]);


      const [dataFromPaginate, setDataFromPaginate] = useState(null);
      const updateDataFromPaginate = (data) => setDataFromPaginate(data);
      const renderUserList = () =>
        dataFromPaginate
          ? dataFromPaginate.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>
                  <Link to={`/admin/denuncias/tipo/${item.id}`}>
                  <i class="badge-circle badge-circle-light-warning bx bx-edit font-medium-1"></i>
                  </Link>
                </td>
              </tr>
            ))
          : list.data.map((item, index) => {
              if (index < itemPerPage) {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      <Link to={`/admin/denuncias/tipo/${item.id}`}>
                      <i class="badge-circle badge-circle-light-warning bx bx-edit font-medium-1"></i>
                      </Link>
                    </td>
                  </tr>
                );
              } else {
                return null;
              }
            });
      return (
        <section id="table-transactions">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title"></h5>
              <Link to="/admin/denuncias/crear-tipo" className="btn btn-primary  ml-1">
                <i class="bx bx-plus"></i>
              </Link>
              <div class="heading-elements">
                <ul class="list-inline mb-0 d-flex align-items-center">
                  <li>
                    <fieldset class="has-icon-left">
                      <input type="text" class="form-control single-daterange" />
                      <div class="form-control-position">
                        <i class="bx bx-search font-medium-1"></i>
                      </div>
                    </fieldset>
                  </li>
                </ul>
              </div>
            </div>
    
            {list.data ? (
              <Paginate
                data={list.data}
                setData={updateDataFromPaginate}
                itemsPerPage={itemPerPage}
              />
            ) : null}
    
            <div class="table-responsive">
              <table id="table-extended-transactions" class="table mb-0">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Acción</th>
    
                  </tr>
                </thead>
                <tbody>{list.data ? renderUserList() : null}</tbody>
              </table>
            </div>
          </div>
        </section>
      );
};

export default ComplaintTypes;
