import Complaints from "./complaints/index";
import Messages from "./messages/index";
import Users from "./users/index";
import CreateUser from "./users/create";
import UpdateUser from "./users/update";
import Widgets from "./widgets/index";
import Companies from "./companies";
import CreateCompany from "./companies/create";
import UpdateCompany from "./companies/update";
import ComplaintTypes from './complaint-types/index';
import CreateComplaintType from './complaint-types/create';
import UpdateComplainttype from './complaint-types/update';


const routes = [
  {
    name: "Configuración",
    is_header: true,
  },
  {
    path: "/admin/textos",
    name: "Textos",
    is_header: false,
    exact: false,
    is_visible: true,
    icon:'bx bx-file',

    component: Messages,
    meta: {
      requiresAuth: true,
      title: "Denuncias",
      icon: "mdi-account-key",
      permission: "core.view_settingmessage",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Textos",
          path: "/admin/textos/",
        },
      ],
    },
  },
  {
    path: "/admin/usuarios",
    name: "Usuarios",
    is_header: false,
    is_visible: true,
    icon:'bx bxs-group',
    exact: false,
    component: Users,
    meta: {
      requiresAuth: true,
      title: "Denuncias",
      icon: "mdi-account-key",
      permission: "auth.view_user",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Textos",
          path: "/admin/usuarios/",
        },
      ],
    },
  },
  {
    path: "/admin/crear-usuario",
    name: "Nuevo Usuario",
    is_header: false,
    exact: false,
    is_visible: false,
    component: CreateUser,
    meta: {
      requiresAuth: true,
      title: "Nuevo Usuario",
      icon: "mdi-account-key",
      permission: "auth.add_user",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Usuarios",
          path: "/admin/usuarios",
        },
        {
          name: "Nuevo Usuario",
          path: "/admin/crear-usuarios",
        },
      ],
    },
  },
  {
    path: "/admin/editar-usuario/:id",
    name: "Editar Usuario",
    is_header: false,
    exact: false,
    is_visible: false,

    component: UpdateUser,
    meta: {
      requiresAuth: true,
      title: "Editar Usuario",
      icon: "mdi-account-key",
      permission: "auth.change_user",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Usuarios",
          path: "/admin/usuarios",
        },
        {
          name: "Editar Usuario",
          path: "/admin/editar-usuarios",
        },
      ],
    },
  },
  {
    path: "/admin/slider",
    name: "Slider",
    is_header: false,
    exact: true,
    is_visible: true,
    icon:'bx bxs-carousel',

    component: Widgets,
    meta: {
      requiresAuth: true,
      title: "Denuncias",
      icon: "mdi-account-key",
      permission: "core.add_sliderimage",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Denuncias",
          path: "/admin/complaints",
        },
      ],
    },
  },
  {
    name: "Administración",
    is_header: true,
  },

  {
    path: "/admin",
    name: "Denuncias",
    is_header: false,
    exact: true,
    is_visible: true,
    icon:'bx bx-message',
    component: Complaints,
    meta: {
      requiresAuth: true,
      title: "Denuncias",
      icon: "mdi-account-key",
      permission: "core.view_complaint",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Denuncias",
          path: "/admin/complaints",
        },
      ],
    },
  },
  {
    path: "/admin/denuncias/tipo",
    name: "Tipos Denuncias",
    is_header: false,
    exact: true,
    is_visible: true,
    icon:'bx bx-list-plus',
    component: ComplaintTypes,
    meta: {
      requiresAuth: true,
      title: "Tipo Denuncias",
      icon: "mdi-account-key",
      permission: "core.view_complainttype",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Tipos Denuncias",
          path: "/admin/denuncias/tipo",
        },
      ],
    },
  },
  {
    path: "/admin/denuncias/crear-tipo",
    name: "Nuevo Tipo Denuncia",
    is_header: false,
    exact: false,
    is_visible: false,
    component: CreateComplaintType,
    meta: {
      requiresAuth: true,
      title: "Nuevo Tipo Denuncia",
      icon: "mdi-account-key",
      permission: "core.add_complainttype",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Tipo Denuncia",
          path: "/admin/denuncias/tipo",
        },
        {
          name: "Nuevo Tipo Denuncia",
          path: "/admin/denuncias/crear-tipo",
        },
      ],
    },
  },
  {
    path: "/admin/denuncias/tipo/:id",
    name: "Editar Tipo Denuncia",
    is_header: false,
    exact: false,
    is_visible: false,
    component: UpdateComplainttype,
    meta: {
      requiresAuth: true,
      title: "Editar Tipo Denuncia",
      icon: "mdi-account-key",
      permission: "core.change_complainttype",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Tipo Denuncia",
          path: "/admin/denuncias/tipo",
        },
        {
          name: "Editar Tipo Denuncia",
          path: "/admin/denuncias/tipo/:id",
        },
      ],
    },
  },
  {
    path: "/admin/empresas/",
    name: "Empresas",
    is_header: false,
    exact: true,
    is_visible: true,
    icon:'bx bx-buildings',
    component: Companies,
    meta: {
      requiresAuth: true,
      title: "Empresas",
      icon:'bx bx-buildings',
      permission: "core.view_company",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Empresas",
          path: "/admin/empresas/",
        },
      ],
    },
  },
  {
    path: "/admin/empresas/crear",
    name: "Nueva Empresa  ",
    is_header: false,
    exact: false,
    is_visible: false,
    component: CreateCompany,
    meta: {
      requiresAuth: true,
      title: "Nueva Empresa",
      icon: "mdi-account-key",
      permission: "core.add_company",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Empresas",
          path: "/admin/empresas/",
        },
        {
          name: "Nuevo Empresa",
          path: "/admin/empresas/crear",
        },
      ],
    },
  },
  {
    path: "/admin/editar-empresa/:id",
    name: "Editar Empresa",
    is_header: false,
    exact: false,
    is_visible: false,
    component: UpdateCompany,
    meta: {
      requiresAuth: true,
      title: "Editar Empresa",
      icon: "mdi-account-key",
      permission: "core.change_company",
      breadcrumb: [
        {
          name: "Admin",
          path: "/admin",
        },
        {
          name: "Empresas",
          path: "/admin/usuarios",
        },
        {
          name: "Editar Empresa",
          path: "/admin/editar-empresa/:id",
        },
      ],
    },
  },
  
];

export default routes;
