import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { COLOR, TEXT_COLOR, APP_NAME } from "../config/index";
import {login} from '../redux/auth'
import img from '../images/maintenance-2.png'
const Login = () => {

  const error = useSelector((state) => state.auth.error);
  const auth = useSelector((state) => state.auth.isLogin)
  const [data,setData]=useState({
    username:'',
    password:''
  })

  const dispatch = useDispatch();

  const [msj,setMsj]=useState({
    type:'',
    text:''
  })

  useEffect(() => {
    if(error){
      if(error.msj){
        setMsj({
          type:'danger',
          text:error.msj
        })
      }
    }

    
  }, [auth,error]);

  const handleChange=(e)=>{
    setData({
      ...data,
      [e.target.name]:e.target.value
    })

  }

  const {username,password}=data;
  const handleSubmit=(e)=>{
    e.preventDefault();
    //validar que no esten vacios
    if(username.trim()===''||password.trim()===''){
      setMsj({
        type:'danger',
        text:'Todos los campos son obligatorios'
      })
    }else{
      dispatch(login(data));
    }

  }


  return (
    <div className="app-content content">
      <div className="content-overlay"></div>
      <div className="content-wrapper">
        <div className="content-header row"></div>
        <div className="content-body">
          <section id="auth-login" className="row flexbox-container">
            <div className="col-xl-8 col-11">
              <div className="card bg-authentication mb-0">
                <div className="row m-0">
                  <div className="col-md-6 col-12 px-0">
                    <div className="card disable-rounded-right mb-0 p-2 h-100 d-flex justify-content-center">
                      <div className="card-header pb-1">
                        <div className="card-title">
                          <h4 className="text-center mb-2" 
                          style={{
                            textTransform: 'initial',
                          }}>{APP_NAME}</h4>
                        </div>
                      </div>
                      <div className="card-body">
                        <form method="post" onSubmit={e=>handleSubmit(e)}>
                          {
                            msj.type!=""?(
                              <div className={`alert alert-${msj.type}`}>
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                  <span aria-hidden="true">×</span>
                                </button>
                                {msj.text}
                              </div>

                            ):null

                          }
                          <div className="form-group mb-50">
                            <label
                              className="text-bold-600"
                              htmlFor="exampleInputEmail1"
                            >
                              Usuario
                            </label>
                            <input
                              type="text"
                              name="username"
                              onChange={e=>handleChange(e)}
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Usuario"
                            />
                          </div>
                          <div className="form-group">
                            <label
                              className="text-bold-600"
                              htmlFor="exampleInputPassword1"
                            >
                              Contraseña
                            </label>
                            <input
                              type="password"
                              name="password"
                              onChange={e=>handleChange(e)}
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Contraseña"
                            />
                          </div>
                          
                          <button
                            type="submit"
                            className="btn glow w-100 position-relative"
                            style={{
                              backgroundColor: COLOR,
                              color: TEXT_COLOR,
                            }}
                          >
                            Iniciar Sesión
                            <i
                              id="icon-arrow"
                              className="bx bx-right-arrow-alt"
                            ></i>
                          </button>
                        </form>
                        <hr />
                        
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-block d-none text-center align-self-center p-3">
                    <img
                      className="img-fluid"
                      src="/base/images/imagen.png"
                      alt="branding logo"
                      height="50%"
                      width="70%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default Login;
