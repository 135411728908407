import React from "react";

const Breadcrumb = ({breadcrumbs,name }) => (
  <div class="content-header row">
    <div class="content-header-left col-12 mb-2 mt-1">
      <div class="breadcrumbs-top">
        <h5 class="content-header-title float-left pr-1 mb-0">{name}</h5>
        <div class="breadcrumb-wrapper d-none d-sm-block">
          <ol class="breadcrumb p-0 mb-0 pl-1">
            <li class="breadcrumb-item">
              <a href="/">
                <i class="bx bx-home-alt"></i>
              </a>
            </li>
            {
                breadcrumbs.map((breadcrumb, index) => (
                    <li class="breadcrumb-item">
                        <a href={breadcrumb.url}>{breadcrumb.name}</a>
                    </li>
                ))
            }
          </ol>
        </div>
      </div>
    </div>
  </div>
);
export default Breadcrumb;