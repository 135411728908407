import React, { useState } from "react";
import "./file-input.css";
const InputFile = ({ handle, i }) => {
  const [type, setType] = useState("");
  const [file, setFile] = useState("");
  const [url, setUrl] = useState("");
  const handleChange = (e) => {
    console.log("Archivo");
    if(e.target.files[0]){
      setType(e.target.files[0].type);
      setFile(e.target.files[0].name);
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      handle(e);
      reader.onload = (e) => {
        setUrl(e.target.result);
      }
    }
   
    
  };
  return (
    <div className="row mt-2">

      <div className="col-md-2 col-sm ">
        {
          type.startsWith("image") ? (
            <img src={
              url
            } alt="img" className="img-fluid" />
          ) : type.startsWith("video") ? (
            <video src={
              url
            }  controls className="col-md-4 col-sm-12" />

          ) : type.startsWith("audio") ? (
            <audio src={
              url
            }  controls className="col-md-4 col-sm-12" />
            ) : (
              <i className="bx bx-file" aria-hidden="true"></i>
            )
        }
      </div>
      <div class="col-md-2 col">
        <h6>{file == "" ? "Nombre Archivo" : file}</h6>
      </div>

      <div class="col-md-2 col">
        <div class="file-input">
          <input
            type="file"
            name={`file-input-${i}`}
            id={`file-input-${i}`}
            class="file-input__input"
            onChange={(e) => handleChange(e)}
            accept="image/*,video/*,audio/*"
          />
          <label class="file-input__label" for={`file-input-${i}`}>
            <span>Seleccionar</span>
          </label>
        </div>
      </div>
    </div>
  );
};
export default InputFile;
