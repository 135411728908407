import React , { useEffect, useState } from "react";
import { APP_NAME } from "../../config/index";
import routes from "../../pages/admin/routes";
import {Link,useLocation} from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import logo from '../../images/logo_orocash_2021.png'
const Sidebar = () => {
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  const [perm, setPerm] = useState([]);
  useEffect(() => {
    if (token) {
      setPerm(token.permissions);
    }},[token])
  return (
    <div
      className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
      data-scroll-to-active="true"
    >
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <a className="navbar-brand" href="/">
              <div className="brand-logo">
                <img alt="" width="500%" src={logo}/>
              </div>
              <h3 className="brand-text mb-0">{}</h3>
            </a>
          </li>
          <li className="nav-item nav-toggle">
            <a
              className="nav-link modern-nav-toggle pr-0"
              data-toggle="collapse"
            >
              <i className="bx bx-x d-block d-xl-none font-medium-4 primary"></i>
              <i
                className="toggle-icon bx bx-disc font-medium-4 d-none d-xl-block primary"
                data-ticon="bx-disc"
              ></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="shadow-bottom"></div>
      <div className="main-menu-content">
        <ul
          className="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
          data-icon-style="lines"
        >
          {routes.map((route, index) =>
            route.is_header  ? (
              <li className=" navigation-header">
                <span>{route.name}</span>
              </li>
            ) : route.is_visible && perm.indexOf(route.meta.permission)!==-1 ? (
              <li className={`nav-item ${location.pathname===route.path?"active":""}`} key={index}>
                <Link to={route.path} className="nav-link">
                  <i className={route.icon}></i>
                  <span className="menu-title">{route.name}</span>
                </Link>
              </li>
            ):null
          )}
          
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
