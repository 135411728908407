import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    createComplaintTypes,
  clear_state,
  CREATE_COMPLAINT_TYPE,
} from "../../../redux/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const CreateComplainttype = () => {
  const _token = useSelector((state) => state.auth.token);
  const _create_company = useSelector((state) => state.comp.create_complaint_type);
  const [token, setToken] = useState("");

  let history = useHistory();

  const [data, setData] = useState({
    name: "",
  });
  const [msj, setMsj] = useState({
    type: "",
    text: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (_token) {
      setToken(_token.token);
    }
    if (_create_company) {
      if (_create_company.status === 201) {
        dispatch(
          clear_state(CREATE_COMPLAINT_TYPE, {
            message: "",
            status: 0,
          })
        );
        history.push("/admin/denuncias/tipo");

        window.location.reload();
      } else {
        setMsj({
          type: "danger",
          text: _create_company.message,
        });
      }
    }
  }, [_create_company,_token,dispatch,history]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const { name } = data;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setMsj({
        text: "El campo nombre es obligatorio",
        type: "danger",
      });
    } else {
      dispatch(createComplaintTypes(token, data));
    }
  };
  return (
    <form method="post" onSubmit={(e) => handleSubmit(e)}>
      {msj.text !== "" ? (
        <div className={`alert alert-${msj.type}`}>{msj.text}</div>
      ) : null}
      <div className="form-group">
        <label htmlFor="nombre">Nombre</label>
        <input
          id="nombre"
          type="text"
          className="form-control"
          onChange={(e) => handleChange(e)}
          name="name"
          aria-describedby="emailHelp"
          placeholder="Nombre"
        />
      </div>

      <button type="submit" className="btn btn-primary">
        Guardar
      </button>
      <Link to="/admin/denuncias/tipo" className="btn btn-danger">
        Cancelar
      </Link>
    </form>
  );
};

export default CreateComplainttype;
