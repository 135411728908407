import {
  BASE_API,
  COMPLAINTS,
  COMPLAINTS_LIST,
  MESSAGES_LIST,
  MESSAGES_UPDATE,
  IMAGE_CREATE,
  IMAGE_LIST,
  IMAGE_DELETE,
  SETTING_GET,
  COMPLAINT_TYPES_LIST,
  COMPLAINT_TYPES_CREATE,
  COMPLAINT_TYPES_UPDATE,
  MEDIA_COMPLAINT_CREATE,
  MEDIA_COMPLAINT_LIST,
  COMPANY_CREATE,
  COMPANY_LIST,
  COMPANY_UPDATE,
  REPORTER_CREATE,
  TASK_STATUS,
} from "../config/index";
import axios from "axios";

/**
 * @constant
 * @type {JsonObject}
 * @default
 * @description
 * This is the default state of the reducer
 *
 */

const init = {
  messages: [],
  create_complaint: {
    status: 0,
    message: "",
  },
  get_complaint: {
    status: 0,
    message: "",
    data: [],
  },
  get_complaint_by_id: {
    status: 0,
    message: "",
    data: {},
  },
  get_complaint_by_user: {
    status: 0,
    message: "",
    data: [],
  },
  get_messages: {
    status: 0,
    message: "",
    data: [],
  },
  update_message: {
    status: 0,
    message: "",
  },
  create_image: {
    status: 0,
    message: "",
  },
  get_image: {
    status: 0,
    message: "",
    data: [],
  },
  delete_image: {
    status: 0,
    message: "",
  },
  get_setting: {
    status: 0,
    message: "",
    data: {},
  },
  get_companies: {
    status: 0,
    message: "",
    data: [],
  },
  create_company: {
    status: 0,
    message: "",
  },
  update_company: {
    status: 0,
    message: "",
  },
  get_complaint_types: {
    status: 0,
    message: "",
    data: [],
  },
  create_complaint_type: {
    status: 0,
    message: "",
  },
  update_complaint_type: {
    status: 0,
    message: "",
  },
  get_reporters: {
    status: 0,
    message: "",
    data: {},
  },
  create_media_complaint: {
    status: 0,
    message: "",
  },
  get_media_complaint: {
    status: 0,
    message: "",
    data: [],
  },
};

export const CREATE_COMPLAINT = "CREATE_COMPLAINT";
const GET_COMPLAINT = "GET_COMPLAINT";
const GET_COMPLAINT_BY_ID = "GET_COMPLAINT_BY_ID";
const GET_COMPLAINT_BY_USER = "GET_COMPLAINT_BY_USER";

export const CREATE_MEDIA_COMPLAINT = "CREATE_MEDIA_COMPLAINT";
export const GET_MEDIA_COMPLAINT = "GET_MEDIA_COMPLAINT";

const GET_MESSAGES = "GET_MESSAGES";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const GET_IMAGES_SLIDERS = "GET_IMAGES_SLIDERS";
export const DELETE_IMAGE = "DELETE_IMAGE";

export const GET_SETTING_BY_KEY = "GET_SETTING_BY_KEY";

export const CREATE_COMPLAINT_TYPE = "CREATE_COMPLAINT_TYPE";
export const UPDATE_COMPLAINT_TYPE = "UPDATE_COMPLAINT_TYPE";
const GET_COMPLAINT_TYPES = "GET_COMPLAINT_TYPES";

export const CREATE_COMPANY = "CREATE_COMPANY";
const GET_COMPANIES = "GET_COMPANIES";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const CREATE_REPORT = "CREATE_REPORT";

export const complaintReducer = (state = init, action) => {
  switch (action.type) {
    case CREATE_COMPLAINT:
      return {
        ...state,
        create_complaint: action.payload,
      };
    case GET_COMPLAINT:
      return {
        ...state,
        get_complaint: action.payload,
      };
    case GET_COMPLAINT_BY_ID:
      return {
        ...state,
        get_complaint_by_id: action.payload,
      };
    case CREATE_REPORT:
      return {
        ...state,
        create_report: action.payload,
      };
    case GET_COMPLAINT_BY_USER:
      return {
        ...state,
        get_complaint_by_user: action.payload,
      };
    case CREATE_MEDIA_COMPLAINT:
      return {
        ...state,
        create_media_complaint: action.payload,
      };
    case GET_MEDIA_COMPLAINT:
      return {
        ...state,
        get_media_complaint: action.payload,
      };
    case GET_MESSAGES:
      return {
        ...state,
        get_messages: action.payload,
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        update_message: action.payload,
      };
    case UPLOAD_IMAGE:
      return {
        ...state,
        create_image: action.payload,
      };
    case GET_IMAGES_SLIDERS:
      return {
        ...state,
        get_image: action.payload,
      };
    case DELETE_IMAGE:
      return {
        ...state,
        delete_image: action.payload,
      };
    case GET_SETTING_BY_KEY:
      return {
        ...state,
        get_setting: action.payload,
      };
    case GET_COMPLAINT_TYPES:
      return {
        ...state,
        get_complaint_types: action.payload,
      };
    case CREATE_COMPLAINT_TYPE:
      return {
        ...state,
        create_complaint_type: action.payload,
      };
    case UPDATE_COMPLAINT_TYPE:
      return {
        ...state,
        update_complaint_type: action.payload,
      };
    case GET_COMPANIES:
      return {
        ...state,
        get_companies: action.payload,
      };
    case CREATE_COMPANY:
      return {
        ...state,
        create_company: action.payload,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        update_company: action.payload,
      };
    default:
      return state;
  }
};

/**
 * @function
 * @name createComplaint
 * @description
 * This function is used to create a complaint
 * @param {JsonObject} data
 * @returns {JsonObject}
 */
export const createComplaint = (data, token, media) => async (dispatch) => {
  let id;
  try {
    const res = await axios.post(`${BASE_API}${COMPLAINTS}`, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    id = res.data.id;

    dispatch({
      type: CREATE_COMPLAINT,
      payload: {
        status: res.status,
        message: "Denuncia enviada correctamente",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_COMPLAINT,
        payload: {
          status: error.response.status,
          message: "Error al enviar denuncia",
        },
      });
    } else {
      dispatch({
        type: CREATE_COMPLAINT,
        payload: { status: 500, message: "Error al enviar denuncia" },
      });
    }
  }

  try {
    
    if (media.length > 0) {
      console.log(media.length);
      for (let i = 0; i <= media.length-1; i++) {
        let files = new FormData();
        //get type file
        let type = media[i].type;
        files.append("files", media[i]);
        files.append("complaint_id", id);
        files.append("type", type);
        await axios.post(
          `${BASE_API}${MEDIA_COMPLAINT_CREATE}`,
          files,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
      }
      
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_MEDIA_COMPLAINT,
        payload: {
          status: error.response.status,
          message: "Error al enviar denuncia",
        },
      });
    } else {
      dispatch({
        type: CREATE_MEDIA_COMPLAINT,
        payload: { status: 500, message: "Error al enviar denuncia" },
      });
    }
  }
};

/**
 * @function
 * @name getComplaintsMedia
 * @param {str} token 
 * this function is used to get all media complaints
 * @returns {JsonArray}
 */
export const getComplaintsMedia = (token) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_API}${MEDIA_COMPLAINT_LIST}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: GET_MEDIA_COMPLAINT,
      payload: {
        status: res.status,
        message: "Lista de denuncias",
        data: res.data,
      }
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_MEDIA_COMPLAINT,
        payload: {
          status: error.response.status,
          data: [],
          message: "Error al obtener denuncias",
        },
      });
    } else {
      dispatch({
        type: GET_MEDIA_COMPLAINT,
        data: [],
        payload: { status: 500, message: "Error al obtener denuncias" },
      });
    }
  }
}

/**
 * @function
 * @name createMediaComplaint
 * @description
 * This function is used to create a media complaint
 * @param {str} token
 * @returns {JsonObject}
 */
export const createMediaComplaint = (data, token) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_API}${MEDIA_COMPLAINT_CREATE}`, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: CREATE_MEDIA_COMPLAINT,
      payload: {
        status: res.status,
        message: "Denuncia enviada correctamente",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_MEDIA_COMPLAINT,
        payload: {
          status: error.response.status,
          message: "Error al enviar denuncia",
        },
      });
    } else {
      dispatch({
        type: CREATE_MEDIA_COMPLAINT,
        payload: { status: 500, message: "Error al enviar denuncia" },
      });
    }
  }
};

/**
 * @function
 * @name getComplaint
 * @description
 * This function is used to get all complaint
 * @param {JsonObject} data
 * @returns {JsonObject}
 */
export const getComplaint = (token) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_API}${COMPLAINTS_LIST}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: GET_COMPLAINT,
      payload: {
        status: res.status,
        message: "",
        data: res.data,
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_COMPLAINT,
        payload: {
          status: error.response.status,
          message: "Error al obtener denuncias",
        },
      });
    } else {
      dispatch({
        type: GET_COMPLAINT,
        payload: { status: 500, message: "Error al obtener denuncias" },
      });
    }
  }
};

/**
 * @function
 * @name getMessages
 * @description
 * This function is used to get all message
 * @param {JsonObject} data
 * @returns {JsonObject}
 *
 */
export const getMessages = () => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_API}${MESSAGES_LIST}`);
    dispatch({
      type: GET_MESSAGES,
      payload: {
        status: res.status,
        message: "",
        data: res.data,
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_MESSAGES,
        payload: {
          status: error.response.status,
          message: "Error al obtener mensajes",
          data: [],
        },
      });
    } else {
      dispatch({
        type: GET_MESSAGES,
        payload: {
          status: 500,
          message: "Error al obtener mensajes",
          data: [],
        },
      });
    }
  }
};

/**
 * @function
 * @name updateMessages
 * @description
 * This function is used to get update message
 * @param {JsonObject} data
 * @returns {JsonObject}
 *
 */
export const updateMessages = (data, token) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_API}${MESSAGES_UPDATE}`, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: UPDATE_MESSAGE,
      payload: {
        status: res.status,
        message: "Mensajes actualizados correctamente",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPDATE_MESSAGE,
        payload: {
          status: error.response.status,
          message: "Error al actualizar mensaje",
        },
      });
    } else {
      dispatch({
        type: UPDATE_MESSAGE,
        payload: { status: 500, message: "Error al actualizar mensaje" },
      });
    }
  }
};

/**
 *
 * @param {token} string
 * @description
 * This fuction is for upload image for slider
 * @returns {JsonObject}
 */
export const uploadImage = (data, token) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_API}${IMAGE_CREATE}`, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: UPLOAD_IMAGE,
      payload: {
        status: res.status,
        message: "Imagen subida correctamente",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPLOAD_IMAGE,
        payload: {
          status: error.response.status,
          message: "Error al subir imagen",
        },
      });
    } else {
      dispatch({
        type: UPLOAD_IMAGE,
        payload: { status: 500, message: "Error al subir imagen" },
      });
    }
  }
};

/**
  * @function
  * @name getImagesSliders
  * @description
  * This function is used to get all image slider by type
  * @param {string} type
  * @returns {JsonObject}
  *

**/
export const getImagesSliders = (type) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_API}${IMAGE_LIST}?type=${type}`);
    dispatch({
      type: GET_IMAGES_SLIDERS,
      payload: {
        status: res.status,
        message: "",
        data: res.data,
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_IMAGES_SLIDERS,
        payload: {
          status: error.response.status,
          message: "Error al obtener imagenes",
        },
      });
    } else {
      dispatch({
        type: GET_IMAGES_SLIDERS,
        payload: { status: 500, message: "Error al obtener imagenes" },
      });
    }
  }
};

/**
 *
 * @param {data} JSONObject
 * @param {token} string
 * @description
 * this function is for delete a image
 * @returns
 */
export const deleteImage = (data, token) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_API}${IMAGE_DELETE}`, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: DELETE_IMAGE,
      payload: {
        status: res.status,
        message: "Imagen eliminada correctamente",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: DELETE_IMAGE,
        payload: {
          status: error.response.status,
          message: "Error al eliminar imagen",
        },
      });
    } else {
      dispatch({
        type: DELETE_IMAGE,
        payload: { status: 500, message: "Error al eliminar imagen" },
      });
    }
  }
};

/**
 * @function
 * @name getSettingByKey
 * @description
 * This function is used to get setting by key
 * @param {key} string
 * @returns {JsonObject}
 */
export const getSettingByKey = (key) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_API}${SETTING_GET}${key}`);
    dispatch({
      type: GET_SETTING_BY_KEY,
      payload: {
        status: res.status,
        message: "",
        data: res.data,
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_SETTING_BY_KEY,
        payload: {
          status: error.response.status,
          message: "Error al obtener configuracion",
        },
      });
    } else {
      dispatch({
        type: GET_SETTING_BY_KEY,
        payload: {
          status: 500,
          message: "Error al obtener configuracion",
          data: {},
        },
      });
    }
  }
};

export const createCompanies = (token, data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_API}${COMPANY_CREATE}`, data, {
      headers: {
        Authorization: "Token " + token,
      },
    });
    dispatch({
      type: CREATE_COMPANY,

      payload: {
        status: res.status,
        message: "Empresas creadas correctamente",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_COMPANY,
        payload: {
          status: error.response.status,
          message: "Error al crear empresas",
        },
      });
    } else {
      dispatch({
        type: CREATE_COMPANY,
        payload: { status: 500, message: "Error al crear empresas" },
      });
    }
  }
};

export const updateCompany = (id, data, token) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_API}${COMPANY_UPDATE}/${id}`, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: UPDATE_COMPANY,
      payload: {
        status: res.status,
        message: "Empresa actualizada correctamente",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPDATE_COMPANY,
        payload: {
          status: error.response.status,
          message: "Error al actualizar empresa",
        },
      });
    } else {
      dispatch({
        type: UPDATE_COMPANY,
        payload: { status: 500, message: "Error al actualizar empresa" },
      });
    }
  }
};

/**
 *
 * @sumary funcion para obtener todas las companies
 * @param {data} JSONObject
 * @param {token} str
 * @returns void
 */
export const getCompanies = (token) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_API}${COMPANY_LIST}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: GET_COMPANIES,
      payload: {
        status: res.status,
        message: "",
        data: res.data,
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_COMPANIES,
        payload: {
          status: error.response.status,
          message: "Error al obtener empresas",
        },
      });
    } else {
      dispatch({
        type: GET_COMPANIES,
        payload: {
          status: 500,
          message: "Error al obtener empresas",
        },
      });
    }
  }
};

/**
 *
 * @sumary funcion para actualizar un tipo de denuncia
 * @param {token} str
 * @param {id} int
 * @param {data} JSONObject
 * @returns void
 */
export const updateComplaintType = (id, data, token) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${BASE_API}${COMPLAINT_TYPES_UPDATE}/${id}`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_COMPLAINT_TYPE,
      payload: {
        status: res.status,
        message: "Tipo de denuncia actualizado correctamente",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPDATE_COMPLAINT_TYPE,
        payload: {
          status: error.response.status,
          message: "Error al actualizar Tipo de denuncia",
        },
      });
    } else {
      dispatch({
        type: UPDATE_COMPLAINT_TYPE,
        payload: {
          status: 500,
          message: "Error al actualizar Tipo de denuncia",
        },
      });
    }
  }
};

/**
 *
 * @sumary funcion para crear un tipo de denuncia
 * @param {token} str
 * @param {data} JSONObject
 * @returns void
 */
export const createComplaintTypes = (token, data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_API}${COMPLAINT_TYPES_CREATE}`, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: CREATE_COMPLAINT_TYPE,
      payload: {
        status: res.status,
        message: "Tipo de denuncia creada correctamente",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_COMPLAINT_TYPE,
        payload: {
          status: error.response.status,
          message: "Error al crear tipo de denuncia",
        },
      });
    } else {
      dispatch({
        type: CREATE_COMPLAINT_TYPE,
        payload: { status: 500, message: "Error al crear tipo de denuncia" },
      });
    }
  }
};

/**
 *
 * @sumary funcion para obtener todas las todos los complaint_typs
 * @param {token} str
 * @returns void
 */
export const getComplaintTypes = (token) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_API}${COMPLAINT_TYPES_LIST}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    dispatch({
      type: GET_COMPLAINT_TYPES,
      payload: {
        status: res.status,
        message: "",
        data: res.data,
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_COMPLAINT_TYPES,
        payload: {
          status: error.response.status,
          message: "Error al obtener tipos de denuncias",
        },
      });
    } else {
      dispatch({
        type: GET_COMPLAINT_TYPES,
        payload: {
          status: 500,
          message: "Error al obtener tipos de denuncias",
        },
      });
    }
  }
};

export const create_report = (token, data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_API}${REPORTER_CREATE}`, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: CREATE_REPORT,
      payload: {
        status: res.status,
        data: res.data,
        message: "Reporte creado correctamente",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_REPORT,
        payload: {
          status: error.response.status,
          data: error.response.data,
          message: "Error al crear reporte",
        },
      });
    } else {
      dispatch({
        type: CREATE_REPORT,
        payload: { status: 500, message: "Error al crear reporte", data: {} },
      });
    }
  }
};

export const get_status_task = async (task_id, token) => {
  const res = await axios.get(`${BASE_API}${TASK_STATUS}${task_id}`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
  });

  return res;
};

/****
 * @sumary funcion para limpiar un estado
 * @returns {JsonObject}
 *
 */
export const clear_state = (type, data) => (dispatch) => {
  dispatch({ type: type, payload: data });
};
