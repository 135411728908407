import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import basicSlider from "../../../components/widgets/basic-slider";
import ReactDOM from "react-dom";
const Widgets = () => {
  const _get_all_widgets = useSelector((state) => state.comp.get_all_widget);
  const [lista, setLista] = useState({
    data: [],
    status: 0,
    message: "",
  });

  useEffect(() => {
    setLista(_get_all_widgets);
  }, [_get_all_widgets]);
  return (
    <basicSlider.data.create/>
  );
};

export default Widgets;
