import React, { useEffect,useState } from "react";
import Navbar from "../../components/admin/navbar";
import Sidebar from "../../components/admin/sidebar";
import Breadcrumb from "../../components/admin/breadcrumb";
import { BrowserRouter as Router, Route, Link, Switch,useHistory } from "react-router-dom";
import routes from "./routes";
import { useSelector, useDispatch } from "react-redux";
import { getComplaint,getImagesSliders,getCompanies,getComplaintTypes,getComplaintsMedia} from "../../redux/core";
import {get_users} from "../../redux/auth";
const Admin = () => {
  const token = useSelector((state) => state.auth.token);
  const [perm, setPerm] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    document.body.classList.add("vertical-layout");
    document.body.classList.add("vertical-menu-modern");
    document.body.classList.add("2-columns");
    document.body.classList.add("navbar-sticky");
    document.body.classList.add("footer-static");
    document.body.removeAttribute("data-col");
    if (token) {
      dispatch(getComplaint(token.token));
      dispatch(get_users(token.token));
      dispatch(getImagesSliders(token.token,'basic-slider'));
      dispatch(getCompanies(token.token));
      dispatch(getComplaintsMedia(token.token));
      dispatch(getComplaintTypes(token.token));
      setPerm(token.permissions);
    }else{
      history.push('/');
    }
  }, [token]);
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="content-wrapper">
          <div className="content-body">
              {routes.map((route, index) =>
                !route.is_header && perm.indexOf(route.meta.permission)!==-1 ? (
                  <>
                    <Route key={index} path={route.path} exact={route.exact}>
                      <Breadcrumb
                        key={index}
                        name={route.name}
                        breadcrumbs={route.meta.breadcrumb}
                      />
                      {<route.component />}
                    </Route>
                  </>
                ) : null
              )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Admin;
