import React from "react";
import { COLOR } from "../config/index";

const Footer = () => {
  return (
    <footer>
        <div class="container">
            <div class="row">
                
                <div class="col-sm-12 col-lg-12 text-center">
                    <i class="fa fa-copyright fa-3x sr-contact" data-sr-id="7" 
                    
                    ></i>
                    <p><a href="#" class="email_us">
                    {new Date().getFullYear} © INNODE</a></p>

                </div>
            </div>
        </div>
    </footer>
  );
};
export default Footer;
