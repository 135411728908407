import React from 'react';
import './modal.css';

const Modal = ({ handleClose, show, children,className, style }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
      <div className={showHideClassName}>
        <section className={className} style={{style}}>
          {children}
          
        </section>
      </div>
    );
}

export default Modal;