import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Paginate from "../../../components/admin/paginate";
import Modal from "../../../components/admin/modal";
import moment from "moment";
import {BASE_API} from '../../../config/index'

const Complaints = () => {
  const get_complaint = useSelector((state) => state.comp.get_complaint);
  const _get_companies = useSelector((state) => state.comp.get_companies);
  const _get_media_complaint = useSelector((state) => state.comp.get_media_complaint);
  const get_users = useSelector((state) => state.auth.get_users);
  const _get_complaint_type = useSelector(
    (state) => state.comp.get_complaint_types
  );
  const [complaint, setComplaint] = useState({
    status: "",
    message: "",
    data: [],
  });
  const [mediaComplaint, setMediaComplaint] = useState({
    status: "",
    message: "",
    data: [],
  });
  const [_users, setUsers] = useState({
    status: "",
    message: "",
    data: [],
  });
  const [dataFromPaginate, setDataFromPaginate] = useState(null);
  useEffect(() => {
    if (get_complaint) {
      setComplaint(get_complaint);
    }
    if (get_users) {
      setUsers(get_users);
    }
    console.log("mediaComplaint", _get_media_complaint);
    if(_get_media_complaint){
      setMediaComplaint(_get_media_complaint);
    }
  }, [get_complaint, _get_companies, _get_complaint_type,_get_media_complaint,get_users]);
  const [usersPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    date: "",
    text: "",
    show: false,
    user: "",
    company: "",
    type: "",
    reported_person: "",
  });
  const [tipoFiltro, setTipoFiltro] = useState(1);
  const [tipoFecha, setTipoFecha] = useState(0);

  //hook para aplicar los filtros de la fecha segin la opcion seleccionada
  const [fecha, setFecha] = useState(null);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);

  const updateDataFromPaginate = (data) => setDataFromPaginate(data);
  const renderUserList = () =>
    dataFromPaginate
      ? dataFromPaginate.map((item, index) => (
          <tr key={index}>
            <td>{item.date}</td>

            <td>
              {item.text.length > 20
                ? item.text.substring(0, 40) + " ..."
                : item.text}
            </td>
            <td>{item.user}</td>

            <td>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowModal(true);
                  setModalData({
                    id: item.id,
                    date: item.date,
                    text: item.text,
                    show: true,
                    user: item.user,
                    type: item.type,
                    company: item.company,
                    reported_person: item.reported_person,
                  });
                }}
                href="#"
              >
                <i class="badge-circle badge-circle-light-warning bx bxs-show font-medium-1"></i>{" "}
              </a>
            </td>
          </tr>
        ))
      : complaint.data.map((item, index) => {
          if (index < usersPerPage) {
            return (
              <tr key={index}>
                <td>{item.date}</td>

                <td>
                  {item.text.length > 20
                    ? item.text.substring(0, 40) + " ..."
                    : item.text}
                </td>
                <td>{item.user}</td>

                <td>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowModal(true);
                      setModalData({
                        id: item.id,
                        date: item.date,
                        text: item.text,
                        show: true,
                        user: item.user,
                        type: item.type,
                        company: item.company,
                        reported_person: item.reported_person,
                      });
                    }}
                  >
                    <i class="badge-circle badge-circle-light-warning bx bxs-show font-medium-1"></i>{" "}
                  </a>
                </td>
              </tr>
            );
          } else {
            return null;
          }
        });

  const filtrarEmpresa = (e) => {
    if (get_complaint) {
      if (e.target.value === "0") {
        setComplaint(get_complaint);
      } else {
        const data = get_complaint.data.filter((item) => {
          return item.company === e.target.value;
        });
        setComplaint({
          status: get_complaint.status,
          message: get_complaint.message,
          data: data,
        });
      }
    }
  };

  const filtrarTipoDenuncia = (e) => {
    if (get_complaint) {
      if (e.target.value === "0") {
        setComplaint(get_complaint);
      } else {
        const data = get_complaint.data.filter((item) => {
          return item.type === e.target.value;
        });
        setComplaint({
          status: get_complaint.status,
          message: get_complaint.message,
          data: data,
        });
      }
    }
  };

  const filtarRangoFecha = () => {
    //filtar get_complaint.data por rango de fecha seleccionado fechaInicio y fechaFin
    if (get_complaint) {
      if (fechaInicio && fechaFin) {
        const data = get_complaint.data.filter((item) => {
          return moment(item.date).isBetween(fechaInicio, fechaFin);
        });
        setComplaint({
          status: get_complaint.status,
          message: get_complaint.message,
          data: data,
        });
        setDataFromPaginate(data);
      }
    }
  };

  const handleFechaChange = (e) => {
    moment().locale("es");

    setFecha(e.target.value);
    if (get_complaint) {
      setDataFromPaginate(
        get_complaint.data.filter(
          (item) =>
            moment(item.date).format("DD-MM-YYYY") ==
            moment(e.target.value).format("DD-MM-YYYY")
        )
      );
      setComplaint({
        status: get_complaint.status,
        message: get_complaint.message,
        data: get_complaint.data.filter(
          (item) =>
            moment(item.date).format("DD-MM-YYYY") ==
            moment(e.target.value).format("DD-MM-YYYY")
        ),
      });
    } else {
      console.log("vacio");
    }
  };

  const hadleTipoFiltroFecha = (e) => {
    setTipoFecha(e.target.value);
    moment().locale("es");

    if (get_complaint) {
      setComplaint(get_complaint);
      if (e.target.value === "0") {
        if (get_complaint.data.length > 0) {
          setComplaint({ ...complaint, data: get_complaint.data });
          setDataFromPaginate(get_complaint.data);
        }
      } else if (e.target.value === "1") {
        //de complaint.data filtrar por fecha actual
        if (get_complaint.data.length > 0) {
          setComplaint({
            ...complaint,
            data: get_complaint.data.filter((item) => {
              return (
                moment(item.date).format("DD-MM-YYYY") ==
                moment(new Date().getTime()).format("DD-MM-YYYY")
              );
            }),
          });
          setDataFromPaginate(
            get_complaint.data.filter((item) => {
              return (
                moment(item.date).format("DD-MM-YYYY") ==
                moment(new Date()).format("DD-MM-YYYY")
              );
            })
          );
        }
      } else if (e.target.value === "2") {
        //de complaint.data filtrar por fecha de ayer
        if (get_complaint.data.length > 0) {
          setComplaint({
            ...complaint,
            data: get_complaint.data.filter((item) => {
              return (
                moment(item.date).format("DD-MM-YYYY") ==
                moment(new Date().setDate(new Date().getDate() - 1)).format(
                  "DD-MM-YYYY"
                )
              );
            }),
          });
          setDataFromPaginate(
            get_complaint.data.filter((item) => {
              return (
                moment(item.date).format("DD-MM-YYYY") ==
                moment(new Date().setDate(new Date().getDate() - 1)).format(
                  "DD-MM-YYYY"
                )
              );
            })
          );
        }
      } else if (e.target.value === "3") {
        //de complaint.data filtrar por fecha de hace 7 dias
        if (get_complaint.data.length > 0) {
          setComplaint({
            ...complaint,
            data: get_complaint.data.filter((item) => {
              return (
                moment(item.date).format("DD-MM-YYYY") ==
                moment(new Date().setDate(new Date().getDate() - 7)).format(
                  "DD-MM-YYYY"
                )
              );
            }),
          });
          setDataFromPaginate(
            get_complaint.data.filter((item) => {
              return (
                moment(item.date).format("DD-MM-YYYY") ==
                moment(new Date().setDate(new Date().getDate() - 7)).format(
                  "DD-MM-YYYY"
                )
              );
            })
          );
        }
      } else if (e.target.value == "4") {
        //de complaint.data filtrar por el mes actual
        if (get_complaint.data.length > 0) {
          setComplaint({
            ...complaint,
            data: get_complaint.data.filter((item) => {
              return new Date(item.date).getMonth() == new Date().getMonth();
            }),
          });
          setDataFromPaginate(
            get_complaint.data.filter((item) => {
              return new Date(item.date).getMonth() == new Date().getMonth();
            })
          );
        }
      }
    } else {
      setDataFromPaginate(null);
    }
  };

  const handleFiltroUsuario = (e) => {
    if (get_complaint) {
      if (e.target.value === "0") {
        setComplaint({
          ...complaint,
          data: get_complaint.data,
        });
        setDataFromPaginate(get_complaint.data);
      } else {
        setComplaint({
          ...complaint,
          data: get_complaint.data.filter((item) => {
            return item.user === e.target.value;
          }),
        });
        setDataFromPaginate(
          get_complaint.data.filter((item) => {
            return item.user === e.target.value;
          })
        );
      }
    }
  };
  return (
    <section id="table-transactions">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title"></h5>
          <div class="container">
            <ul class="list-inline mb-0 d-flex row">
              <li className="col-xl-3 col-lg-6 col-md-6 col-xs-12">
                <fieldset class="has-icon-left">
                  <select
                    type="text"
                    class="form-control single-daterange"
                    onChange={(e) => setTipoFiltro(e.target.value)}
                  >
                    <option value="1">Filtrar por fecha</option>
                    <option value="2">Filtrar por usuario</option>
                    <option value="3">Filtrar por empresa</option>
                    <option value="4">Filtrar por tipo de denuncia</option>
                  </select>
                </fieldset>
              </li>
              {tipoFiltro == 1 ? (
                <li className="col-xl-3 col-lg-6 col-md-6 col-xs-12">
                  <fieldset class="has-icon-left ">
                    <select
                      type="text"
                      class="form-control single-daterange"
                      onChange={(e) => hadleTipoFiltroFecha(e)}
                    >
                      <option value="0">Todas</option>
                      <option value="1">Hoy</option>
                      <option value="2">Ayer</option>
                      <option value="3">Hace 7 días</option>
                      <option value="4">Este Mes</option>
                      <option value="5">Rango de fecha</option>
                      <option value="6">Seleccionar fecha</option>
                    </select>
                  </fieldset>
                </li>
              ) : tipoFiltro == 2 ? (
                <li className="col-xl-3 col-lg-6 col-md-6 col-xs-12">
                  <fieldset class="has-icon-left">
                    <select
                      className="form-control single-daterange"
                      onChange={(e) => handleFiltroUsuario(e)}
                    >
                      <option value="0">Todos</option>
                      {_users.data.map((item) => (
                        <option value={item.username}>{item.username}</option>
                      ))}
                    </select>
                  </fieldset>
                </li>
              ) : tipoFiltro == 3 ? (
                <li className="col-xl-3 col-lg-6 col-md-6 col-xs-12">
                  <fieldset class="has-icon-left">
                    <select
                      className="form-control single-daterange"
                      onChange={(e) => filtrarEmpresa(e)}
                    >
                      <option value="0">Todos</option>
                      {_get_companies.data.map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </fieldset>
                </li>
              ) : (
                <li className="col-xl-3 col-lg-6 col-md-6 col-xs-12">
                  <fieldset class="has-icon-left">
                    <select
                      className="form-control single-daterange"
                      onChange={(e) => filtrarTipoDenuncia(e)}
                    >
                      <option value="0">Todos</option>
                      {_get_complaint_type.data.map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </fieldset>
                </li>
              )}
              {tipoFiltro == 1 && tipoFecha == 5 ? (
                <>
                  <li className="col-xl-2 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <fieldset class="has-icon-left">
                      <input
                        type="date"
                        class="form-control single-daterange"
                        placeholder="Desde"
                        onChange={(e) => setFechaInicio(e.target.value)}
                      />
                      <div class="form-control-position">
                        <i class="bx bx-calendar font-medium-1"></i>
                      </div>
                    </fieldset>
                  </li>
                  <li className="col-xl-2 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <fieldset class="has-icon-left">
                      <input
                        type="date"
                        class="form-control single-daterange"
                        placeholder="Hasta"
                        onChange={(e) => setFechaFin(e.target.value)}
                      />
                      <div class="form-control-position">
                        <i class="bx bx-calendar font-medium-1"></i>
                      </div>
                    </fieldset>
                  </li>
                  <li className="col-xl-2 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={() => filtarRangoFecha()}
                    >
                      Filtrar
                    </button>
                  </li>
                </>
              ) : tipoFiltro == 1 && tipoFecha == 6 ? (
                <>
                  <li className="col-xl-3 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <fieldset class="has-icon-left">
                      <input
                        type="date"
                        class="form-control single-daterange"
                        onChange={(e) => handleFechaChange(e)}
                      />
                      <div class="form-control-position">
                        <i class="bx bx-calendar font-medium-1"></i>
                      </div>
                    </fieldset>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
        </div>
        {complaint.data ? (
          <Paginate
            data={complaint.data}
            setData={updateDataFromPaginate}
            itemsPerPage={usersPerPage}
          />
        ) : null}
        <div class="table-responsive">
          <table id="table-extended-transactions" class="table mb-0">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Descripcion</th>
                <th>Usuario</th>

                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>{complaint.data ? renderUserList() : null}</tbody>
          </table>
        </div>
      </div>
      <Modal
        show={showModal}
        handleClose={() => setShowModal(!showModal)}
        className="modal-main"
      >
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabel1">
            Datos de la denuncia, queja o reclamo
          </h5>
          <button
            type="button"
            class="close rounded-pill"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowModal(!showModal)}
          >
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body row">
          
          <div class="col-md-12 form-row">
            <div class="form-group col-12">
              <label for="usuario">Usuario</label>
              <input
                type="text"
                class="form-control"
                id="usuario"
                value={modalData.user}
                disabled
              />
            </div>
            <div class="form-group col-12">
              <label for="fecha">Fecha</label>
              <input
                type="text"
                class="form-control"
                id="fecha"
                value={modalData.date}
                disabled
              />
            </div>
            <div class="form-group col-md-6 col-12">
              <label for="empresa">Empresa</label>
              <input
                type="text"
                class="form-control"
                id="empresa"
                value={modalData.company}
                disabled
              />
            </div>
            <div class="form-group col-md-6 col-12">
              <label for="tipo">Tipo de denuncia</label>
              <input
                type="text"
                class="form-control"
                id="tipo"
                value={modalData.type}
                disabled
              />
            </div>
            <div class="form-group col-6">
              <label for="denunciada">Persona denunciada</label>
              <input
                type="text"
                class="form-control"
                id="denunciada"
                value={modalData.reported_person}
                disabled
              />
            </div>
            <div class="form-group col-12">
              <label for="descripcion">Descripción</label>
              <textarea class="form-control" id="descripcion" rows="5" disabled value={modalData.text}>
                
              </textarea>
            </div>
            {
              mediaComplaint.data ? (
              mediaComplaint.data.filter(item => item.complaint === modalData.id).length > 0 ? (
                <div class="form-group col-12">
                  <label for="media">Media</label>
                  <div class="row">
                    {mediaComplaint.data.filter(item => item.complaint === modalData.id).map((item,x) => (
                      <div class="col-md-3 col">
                        <a href={BASE_API.substring(0,BASE_API.length-1)+item.file} target="_blank"> {"Archivo "+(x+1)} </a>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null
              ) : null
            }
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowModal(!showModal)}
          >
            Cerrar
          </button>
        </div>
      </Modal>
    </section>
  );
};
export default Complaints;
